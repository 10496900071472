<template>
  <div>
    <HeroSection />
    <ExplainSection />
    <SecretSection />
    <HelpSection />
    <HowItWorksSection />
    <AdvantagesSection />
    <MotivationSection />
  </div>
</template>
<script>
import HeroSection from "./sections/HeroSection.vue";
import ExplainSection from "./sections/ExplainSection.vue";
import SecretSection from "./sections/SecretSection.vue";
import HelpSection from "./sections/HelpSection.vue";
import HowItWorksSection from "./sections/HowItWorksSection.vue";
import AdvantagesSection from "../NewHome/sections/AdvantagesSection.vue";
import MotivationSection from "./sections/MotivationSection.vue";

export default {
  name: "NewCourses",
  components: {
    HeroSection,
    ExplainSection,
    SecretSection,
    HelpSection,
    HowItWorksSection,
    AdvantagesSection,
    MotivationSection,
  },
};
</script>

<style scoped lang="scss"></style>
