<template>
  <Section class="motivation">
    <div class="motivation__block">
      <div class="motivation__content">
        <Title class="motivation__title">
          {{ $t("you_need_motivation") }}
        </Title>
        <p class="motivation__desc">
          {{ $t("you_need_motivation_desc") }}
        </p>

        <StudyButton>
          {{ $t("learn_free") }}
        </StudyButton>
      </div>

      <img
        class="motivation__img"
        src="@/assets/images/motivation_img.png"
        width="284"
        height="286"
        alt="Motivation"
      />
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";
import StudyButton from "@/components/StudyButton.vue";

export default {
  name: "MotivationSection",
  components: { Section, Title, StudyButton },
};
</script>

<style scoped lang="scss">
.motivation {
  &__block {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #e2e8f0;
    border-radius: 16px;
    padding: 68px 30px;
  }
  &__content {
    max-width: 790px;
  }
  &__title {
    margin-bottom: 12px;
  }
  &__desc {
    font-size: 22px;
    color: #0b1f35;
  }
  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
