import { render, staticRenderFns } from "./SecretSection.vue?vue&type=template&id=9ca54258&scoped=true"
import script from "./SecretSection.vue?vue&type=script&lang=js"
export * from "./SecretSection.vue?vue&type=script&lang=js"
import style0 from "./SecretSection.vue?vue&type=style&index=0&id=9ca54258&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ca54258",
  null
  
)

export default component.exports