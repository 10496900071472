<template>
  <Section class="advantages">
    <div class="advantages__inner">
      <Title class="advantages__title"> {{ $t("our_pros") }} </Title>
      <div class="advantages__content">
        <div class="advantages__content-block">
          <p class="advantages__content-desc">
            {{ $t("our_pros_desc") }}
          </p>

          <div
            class="advantages__blocks"
            v-for="({ icon, title, desc }, index) in advantagesItems"
            :key="index"
          >
            <div class="advantages__block">
              <div class="advantages__block-icon">
                <img :src="icon" alt="icon" width="32" height="33" />
              </div>
              <div>
                <h4 class="advantages__block-title">{{ title }}</h4>
                <p class="advantages__block-desc">
                  {{ desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          class="advantages__img"
          src="@/assets/images/advantages_img.webp"
          width="565"
          height="458"
          alt="Advantages"
          loading="lazy"
        />
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "AdvantagesSection",
  components: { Section, Title },
  data() {
    return {
      advantagesItems: [
        {
          icon: require("@/assets/images/smiley_x_eyes.svg"),
          title: this.$t('without_teachers'),
          desc: this.$t('without_teachers_desc'),
        },
        {
          icon: require("@/assets/images/monitor_play.svg"),
          title: this.$t('without_webinars'),
          desc: this.$t('without_webinars_desc'),
        },
        {
          icon: require("@/assets/images/note.svg"),
          title: this.$t('without_hw'),
          desc: this.$t('without_hw_desc'),
        },
        {
          icon: require("@/assets/images/calendar_blank.svg"),
          title: this.$t('without_deadlines'),
          desc: this.$t('without_deadlines_desc'),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.advantages {
  color: #0b1f35;
  &__inner {
    position: relative;
    background: #ffffff;
    border-radius: 16px;
    padding: 40px 30px;
    min-height: 515px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__content-block {
  }
  &__content-desc {
    max-width: 489px;
    font-size: 22px;
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 10px;
  }
  &__blocks {
    &:not(:last-child) {
      margin-bottom: 34px;
    }
  }
  &__block {
    display: flex;
  }
  &__block-icon {
    padding: 8px;
    width: 48px;
    height: 48px;
    background: #16c95d;
    border-radius: 6px;
    margin-right: 12px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 6px;
      right: 5px;
      width: 18px;
      height: 18px;
      background: url("~@/assets/images/x_circle.svg") no-repeat;
    }
  }
  &__block-title {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 0px;
  }
  &__block-desc {
    font-size: 18px;
    margin: 0px;
  }
  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
} 
@media screen and (max-width: 992px) {
  .advantages {
    &__img {
      display: none;
    }
  }
}
@media screen and (max-width: 468px) {
  .advantages {
    &__title {
      font-size: 24px;
    }
    &__content {
      flex-direction: column;
    }
    &__content-desc {
      font-size: 16px;
    }
    &__img {
      position: relative;
      max-width: 100%;
      height: auto;
    }
    &__block {
      &-title {
        font-size: 18px;
      }
      &-desc {
        font-size: 14px;
      }
    }
  }
}
</style>
