<template>
  <component :is="tag" class="title"><slot /></component>
</template>
<script>
export default {
  name: "Title",
  props: {
    tag: {
      type: String,
      default: "h2",
      required: false,
      validator: (value) => {
        return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: 'Good Timing';
  font-weight: 700;
  font-size: 38px;

  color: #0b1f35;
}
</style>
