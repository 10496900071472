<template>
  <Section class="help">
    <div class="help__block">
      <Title class="help__title">{{ $t("course_will_help") }}</Title>

      <div class="help__features">
        <div
          class="help__feature"
          v-for="(feature, index) in featureItems"
          :key="index"
        >
          {{ feature }}
        </div>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "HelpSection",
  components: { Section, Title },
  data() {
    return {
      featureItems: [
        this.$t('reason_systematic'),
        this.$t('reason_dialog'),
        this.$t('reason_find_work'),
        this.$t('reason_be_best'),
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.help {
  &__block {
    padding: 36px 53px 37px 30px;
    display: flex;
    justify-content: space-between;
    background: #007bfc;
    border-radius: 16px;
  }
  &__title {
    max-width: 419px;
    color: #ffffff;
  }
  &__features {
  }
  &__feature {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    padding-left: 42px;
    &::before {
      content: "";
      position: absolute;
      background: url("~@/assets/images/star.png");
      width: 32px;
      height: 32px;
      top: 0;
      left: 0;
    }
    &:not(:last-child) {
      margin-bottom: 37px;
    }
  }
}
</style>
