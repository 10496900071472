<template>
  <div class="new-tooltip">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tooltip",
};
</script>

<style scoped>
.new-tooltip {
  font-family: 'Good Timing';
  font-weight: 700;
  font-size: 24px;
  padding: 13px 15px;
  color: #ffffff;
  background-color: #16c95d;
  border-radius: 87px;
  transform: rotate(-3deg);
  display: inline-block;
  text-transform: uppercase;
}
</style>
