<template>
  <button @click="$router.push({ name: 'courses-list' })" class="btn" type="button"><slot /></button>
</template>
<script>
export default {
  name: "StudyButton",
};
</script>

<style scoped lang="scss">
.btn {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  background: #007bfc;
  border-radius: 10px;
  padding: 21px 58px 23px 58px;
}
</style>
