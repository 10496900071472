<template>
  <Section class="how-it-work">
    <div class="how-it-work__inner">
      <Title class="how-it-work__title">{{ $t("how_it_works") }}</Title>
      <div class="how-it-work__images">
        <img
          class="how-it-work__image"
          v-for="({ image }, index) in howItWorksImages"
          :key="index"
          :src="image"
          :alt="image"
        />
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "HowItWorksSection",
  components: { Section, Title },
  data() {
    return {
      howItWorksImages: [
        {
          image: require("@/assets/images/how_it_works/1.png"),
        },
        {
          image: require("@/assets/images/how_it_works/2.png"),
        },
        {
          image: require("@/assets/images/how_it_works/3.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.how-it-work {
  &__inner {
    display: flex;
  }
  &__title {
    width: 100%;
    max-width: 455px;
  }
  &__images {
    text-align: right;
  }
  &__image {
    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }
}
</style>
