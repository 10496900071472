<template>
  <Section class="explain">
    <div class="explain__block">
      <div class="explain__content">
        <Title class="explain__title">{{ $t("who_is_manager") }}</Title>
        <p class="explain__desc">
          {{ $t("who_is_manager_desc") }}
        </p>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "ExplainSection",
  components: { Section, Title },
};
</script>

<style scoped lang="scss">
.explain {
  padding-top: 60px;
  &__block {
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: url("~@/assets/images/explain.png") no-repeat;
      right: 0;
      bottom: 0;
      width: 694px;
      height: 266px;
    }
  }
  &__content {
    max-width: 500px;
  }
  &__title {
    max-width: 453px;
    margin-bottom: 14px;
  }
  &__desc {
    font-size: 18px;
    color: #0b1f35;
  }
  &__img {
  }
}
</style>
