<template>
  <Section class="hero">
    <div class="hero__inner">
      <div class="hero__content">
        <div class="hero__text">
          <Title class="hero__title" tag="h1">{{ $t("free_curse_train") }} </Title>
          <p class="hero__desc">
            {{ $t("from_zero") }}
          </p>

          <Tooltip>150 000 ₸</Tooltip>
        </div>
        <StudyButton class="hero__btn">{{ $t("learn_free") }}</StudyButton>
      </div>
      <img
        class="hero__img"
        src="@/assets/images/courses_hero.png"
        alt="Hero courses"
      />
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";
import Tooltip from "@/components/Tooltip.vue";
import StudyButton from "@/components/StudyButton.vue";

export default {
  name: "HeroSection",
  components: { Section, Title, Tooltip, StudyButton },
};
</script>

<style scoped lang="scss">
.hero {
  padding: 14px 0px 0px 0px;
  background: #f8fbff;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__text {
    margin-bottom: 17px;
    > * {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  &__content {
    max-width: 450px;
    padding-top: 61px;
    margin-right: 15px;
    flex-shrink: 0;
  }
  &__title {
    font-size: 50px;
  }
  &__desc {
    max-width: 345px;
    font-weight: 500;
    font-size: 24px;
    color: #0b1f35;
  }
  &__btn {
  }
  &__img {
    max-width: 100%;
  }
}
</style>
