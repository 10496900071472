<template>
  <Section class="secret">
    <div class="secret__content">
      <Title class="secret__title"
        >{{ $t("open_secret") }}</Title
      >
      <div class="secret__blocks">
        <div
          class="secret__block"
          v-for="({ title, image }, index) in secretItems"
          :key="index"
        >
          <img
            class="secret__block-img"
            width="61"
            height="60"
            :src="image"
            :alt="image"
          />
          <p class="secret__block-desc">{{ title }}</p>
        </div>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import Title from "@/components/Title.vue";

export default {
  name: "SecretSection",
  components: { Section, Title },
  data() {
    return {
      secretItems: [
        {
          title: this.$t('secret_reason_1'),
          image: require("@/assets/images/secret/1.png"),
        },
        {
          title: this.$t('secret_reason_2'),
          image: require("@/assets/images/secret/2.png"),
        },
        {
          title: this.$t('secret_reason_3'),
          image: require("@/assets/images/secret/3.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.secret {
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background: #e2e8f0;
    border-radius: 16px;
  }
  &__title {
    max-width: 498px;
  }
  &__blocks {
    width: 100%;
    max-width: 533px;
  }
  &__block {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__block-img {
    margin-right: 14px;
  }
  &__block-desc {
    font-size: 18px;
    color: #0b1f35;
  }
}
</style>
